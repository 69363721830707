.footermain {
  display: flex;
  border: 1px solid #eaeaea;
  width: 1200px;
  height: 200px;
}

.firstleftbox {
  flex-basis: 33%;
  width: 100%;
  height: 150px;
}

.mainheading {
  display: block;
  width: 100%;
  height: 20px;
}

.mainheading2 {
  display: block;
  width: 100%;
  height: 130px;
}

.socialmedialogos {
  display: flex;
  width: 100%;
  height: 30px;

  gap: 20px;
}

.middlebox {
  flex-basis: 33%;
  width: 100%;
  height: 180px;
}
